@import "~bootstrap/scss/bootstrap";
@import "global";

$enable-grid-classes : true;
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/brands.css';

body {
    background-color: lightgray;
}

