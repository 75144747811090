// assets/css/global.scss

$primary: darken(#428bca, 20%);
// the ~ allows you to reference things in node_modules

html, body, header {
    height: 100%;
    width: 100%;
}

.jumbotron{
    background-color: #E9ECEF;
}

body{             
    background: url("../img/background.png") no-repeat center fixed;         

    margin:0;
    padding:0;

    -webkit-background-size: cover; /* pour anciens Chrome et Safari */
    background-size: cover; /* version standardisée */
    //  overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
}

.container-fluid{
    padding : 0 !important;
    margin : 0 !important;
}

.bandeau {
    min-height : 400px;
    padding : 20px;
    background-color : rgba(150,150,150,0.5);
    margin-top : 80px;
    margin-bottom:80px;

}
.bandeau>.row {
    padding: 20px;
}

.doublewhite{
    background-color : rgba(255,255,255,0.8);
    padding-left : 15px;
    padding-right : 15px;
}

.site-footer{
    background-color: #292c2f;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    text-align: left;
    font: bold 16px sans-serif;
    color: white;
    padding: 55px 50px;
    margin-top: 30px;

    a:link {
        color: white;
    }

    /* visited link */
    a:visited {
        color: white;
    }

    /* mouse over link */
    a:hover {
        color: rgba(150,150,150,1);
    }

    /* selected link */
    a:active {
        color: white;
    }
}

.blur {
  filter: blur(5px); /* Vous pouvez ajuster la valeur pour contrôler l'intensité du flou */
}